const getAPIKeys = () => {
  return {
    ingredientsAPI:
      "https://oh8h3rgq94.execute-api.us-east-1.amazonaws.com/test/",
    recipesAPI:
      "https://tlm0pbhq4g.execute-api.us-east-1.amazonaws.com/Testing/GetRecipes",
    recipeIngredientsAPI:
      "https://7p0alm97tb.execute-api.us-east-1.amazonaws.com/test/",
  };
};

export default getAPIKeys;
